import { SegmentServerClient } from './SegmentServerClient'
import { getCookie, setCookie } from '../cookie'
import { updateRef } from './updateRef'
import $ from 'jquery'

const TRACK_DOMAINS = [
  { url: 'https://dashboard', param: 'yzsa' },
  { url: 'https://yardzen.typeform', param: 'anonymousid', replace: true },
  { url: 'https://calendly', param: 'utm_term' },
]

function addTrackingToDOMElements(segment) {
  let pathSlug = new URL(window.location.href).pathname
    .split('/')
    .join('')
    .split('-')
    .join(' ')
  let button_name = 'Purchase ' + pathSlug
  trackPackageByClassName(
    'js-purchase',
    button_name,
    'Button',
    'Shop Packages',
    'Purchase',
    pathSlug
  )

  // Navbar (each of these is in the HTML twice, for desktop and mobile nav)
  trackByClassName(
    'js-quiz-btn',
    'Start My Project',
    'Navigation Start Project CTA',
    'Button'
  )
  trackByClassName('nav_shop_link', 'Shop', 'Navigation Shop Link', 'Button')

  // Home page buttons
  trackById(
    'home_explore_packages_button',
    'Explore Packages',
    'Design Profile CTA',
    'Button'
  )
  trackById(
    'home_budget_learn_more_button',
    'Learn More',
    'Pro Network Click',
    'Button'
  )
  trackById(
    'home_help_me_choose_package_button',
    'Help Me Choose A Package',
    'Bottom Design Profile CTA',
    'Button'
  )
  trackByClassName(
    'hero-cta-start-now',
    'Start Now',
    'Hero Start CTA',
    'Button'
  )
  trackByClassName(
    'hero-cta-schedule-call',
    'Schedula a Free Call',
    'Hero Schedula Call CTA',
    'Button'
  )
  trackById(
    '2d-design-order-now',
    'Order Now',
    'Contractors 2D Design Order Now',
    'Button'
  )
  trackById(
    '3d-design-order-now',
    'Order Now',
    'Contractors 3D Design Order Now',
    'Button'
  )
  //alternative tracking method for split items
  $( "body" ).on( "click", "a", async function(event) {
    if (this.id === "homepage-hero-cta-a" || this.id === "homepage-hero-cta-b") {
        const segmentContent = this.textContent
        const segmentType = 'Button'
        const segmentName = this.id
        
        event.preventDefault()
        await executeAndNavigate(event, () =>
          trackClicked(segmentContent, segmentName, segmentType)
        )
      }
  })

  function trackClicked(button_content, button_name, click_type) {
    return segment.track('Clicked', {
      button_name,
      click_type,
      button_content: button_content.toLowerCase(),
    })
  }

  function trackPackageClicked(
    button_name,
    click_type,
    flow_name,
    button_content,
    package_name
  ) {
    return segment.track('Clicked', {
      button_name,
      click_type,
      flow_name,
      button_content: button_content.toLowerCase(),
      package_name,
    })
  }

  function trackById(id, button_content, button_name, click_type) {
    const element = document.getElementById(id)

    if (element) {
      element.addEventListener('click', async (e) => {
        e.preventDefault()
        await executeAndNavigate(e, () =>
          trackClicked(button_content, button_name, click_type)
        )
      })
    }
  }

  function trackByClassName(
    className,
    button_content,
    button_name,
    click_type
  ) {
    const elements = document.getElementsByClassName(className)

    if (elements.length) {
      Array.from(elements).map((element) => {
        element.addEventListener('click', async (e) => {
          e.preventDefault()
          await executeAndNavigate(e, () =>
            trackClicked(button_content, button_name, click_type)
          )
        })
      })
    }
  }

  function trackPackageByClassName(
    className,
    button_name,
    click_type,
    flow_name,
    button_content,
    package_name
  ) {
    const elements = document.getElementsByClassName(className)

    if (elements.length) {
      Array.from(elements).map((element) => {
        element.addEventListener('click', async (e) => {
          e.preventDefault()
          await executeAndNavigate(e, () =>
            trackPackageClicked(
              button_name,
              click_type,
              flow_name,
              button_content,
              package_name
            )
          )
        })
      })
    }
  }

  function trackGateEmailProvided() {
    const submitBtn = document.getElementById('gate-collect-email')
    if (!submitBtn) return

    submitBtn.addEventListener('gateEmailCollected', async function (event) {
      const email = event.detail.email

      segment.identify({
        email,
      })

      await segment.track('Form Submitted', {
        email: email,
        flow_name: 'Blog',
        form_name: 'Blog Email Gate',
        sub_flow_name: 'lead_capture',
      })
    })
  }

  function trackFooterSignUp() {
    const form = document.querySelector('.footer__signup #gform_1')
    if (!form) return
    form.addEventListener('submit', async function (e) {
      const email = document.querySelector('.footer__signup #input_1_1').value

      await segment.identify({
        email,
        flow_name: 'Footer',
        form_name: 'Marketing footer sign up',
      })

      await segment.track('Form Submitted', {
        email: email,
        flow_name: 'Footer',
        form_name: 'Marketing footer sign up',
        sub_flow_name: 'lead_capture',
      })
    })
  }

  trackGateEmailProvided()
  trackFooterSignUp()
}

function setupLinkTracking(segment, yzsa) {
  TRACK_DOMAINS.forEach(function (link) {
    document
      .querySelectorAll(`a[href*="${link.url}"]`)
      .forEach(function (element) {
        updateRef(
          {
            [link.param]: yzsa,
          },
          element,
          link.replace
        )
        // Add click handlers to track consult call events (calendly)
        if (link.url === 'https://calendly') {
          element.addEventListener('click', (e) => {
            if (element.dataset.clicked !== 'true') {
              e.preventDefault()
              element.dataset.clicked = 'true'
              segment.track('Clicked', {
                provider: 'calendly',
                flow_name: 'consult-call',
                form_name: 'Schedule a call',
                click_type: 'Button',
                button_name: `${element.parentElement.className} consult call button`,
                button_content: element.textContent,
              })
              element.click()
            }
          })
        }
      })
  })
}

async function executeAndNavigate(e, action) {
  try {
    await action()
  } catch (error) {
    console.error(error)
  } finally {
    const to = (e.currentTarget ?? e.target).getAttribute('href')
    to && (window.location.href = to)
  }
}

async function initSegmentClient() {
  let yzsa = getCookie('yzsa')
  if (!yzsa) {
    yzsa = await fetch(php_vars.segmentServerURL + '/rand').then((r) =>
      r.text()
    )
    setCookie(
      'yzsa',
      yzsa,
      365,
      /yardzen\.com$/.test(window.location.origin) ? 'yardzen.com' : ''
    )
  }

  let fbc = getCookie('_fbc')
  let fbp = getCookie('_fbp')

  const segment = new SegmentServerClient(php_vars.segmentServerURL, yzsa)

  // Identify initial Facebook cookies only once per session
  if (!sessionStorage.getItem('sentFbCookies')) {
    segment.identify({ fbc, fbp })
    sessionStorage.setItem('sentFbCookies', 'true')
  }
  segment.page()

  setupLinkTracking(segment, yzsa)
  addTrackingToDOMElements(segment)
}

export default function initSegmentTracking() {
  document.addEventListener('DOMContentLoaded', function () {
    initSegmentClient()
  })
}
